<template>
  <div>
    <div class="page-title">Greenhouse Settings</div>
    <TextField
      placeholder="On-Behalf-Of"
      label="On-Behalf-Of"
      v-model="localValue.data.on_behalf_of"
      required
    />
    <TextField
      placeholder=""
      label="Environment Variable Key"
      v-model="localValue.data.env_var_key"
      required
    />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style scoped lang="scss"></style>
