<template>
  <v-expansion-panels
    class="form-panels"
    accordion
    v-model="panel"
    multiple
    :readonly="readonly"
  >
    <slot></slot>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const panel = this.$slots.default.map((t, i) => i);

    return {
      panel,
    };
  },
  created() {},
};
</script>

<style scoped lang="scss">
.form-panels {
  margin-top: 30px;
  border: 2px solid var(--v-border-base);
  position: static !important;
}

.form-panels::v-deep .form-panel:last-child .v-expansion-panel-content {
  border-bottom: none !important;
}
</style>
