import { ifAuthenticated, ifNotAuthenticated, ifSuperAdmin } from "./auth";

import LoginPage from "@/pages/auth/login.page";
import ForgotPasswordPage from "@/pages/auth/forgot-password.page";
import SetPasswordPage from "@/pages/auth/set-password.page";
import RegisterPage from "@/pages/auth/register.page";

import JoinPage from "@/pages/join.page";

import OnboardingPage from "@/pages/onboarding.page";
import SettingsPage from "@/pages/settings/settings.page";

import DashboardPage from "@/pages/dashboard.page";
import ThemePage from "@/pages/theme.page";
import ThemeTemplatePage from "@/pages/theme-template.page";

import LogsPage from "@/pages/logs.page";
import ShowLogsPage from "@/pages/logs/show.logs.page";
import AddonsPage from "@/pages/addons.page";

import PagesPage from "@/pages/pages.page";
import NewPagePage from "@/pages/pages/new.pages.page";
import EditPagePage from "@/pages/pages/edit.pages.page";
import ShowPagePage from "@/pages/pages/show.pages.page";

import EventsPage from "@/pages/events.page";
import NewEventPage from "@/pages/events/new.events.page";
import EditEventPage from "@/pages/events/edit.events.page";
import ShowEventPage from "@/pages/events/show.events.page";

import VenueEventsPage from "@/pages/venue-events.page";
import NewVenueEventPage from "@/pages/venue-events/new.venue-events.page";
import EditVenueEventPage from "@/pages/venue-events/edit.venue-events.page";

import TicketmasterVenuesPage from "@/pages/ticketmaster-venues.page";
import EditTicketmasterVenuePage from "@/pages/ticketmaster-venues/edit.ticketmaster-venues.page";

import VariablesPage from "@/pages/variables.page";
import NewVariablePage from "@/pages/variables/new.variables.page";
import EditVariablePage from "@/pages/variables/edit.variables.page";
import FindOrCreateVariablePage from "@/pages/variables/find_or_create.variables.page";

import ChatsPage from "@/pages/chats.page";
import NewChatPage from "@/pages/chats/new.chats.page";
import EditChatPage from "@/pages/chats/edit.chats.page";
import ShowChatPage from "@/pages/chats/show.chats.page";
import EditChatMessagePage from "@/pages/chats/messages/edit.chat-messages.page";
import ShowChatMessagePage from "@/pages/chats/messages/show.chat-messages.page";

import SupportPage from "@/pages/support.page";
import NewSupportPage from "@/pages/support/new.support.page";
import EditSupportPage from "@/pages/support/edit.support.page";
import ShowSupportPage from "@/pages/support/show.support.page";
import ShowSupportTicketPage from "@/pages/support/tickets/show.support-tickets.page";

import ProjectsPage from "@/pages/projects.page";
import EditProjectPage from "@/pages/projects/edit.projects.page";
import NewProjectPage from "@/pages/projects/new.projects.page";

import EditTeamPage from "@/pages/teams/edit.teams.page";
import NewTeamPage from "@/pages/teams/new.teams.page";
import EditTeamMemberPage from "@/pages/teams/members/edit.team-members.page";

import WidgetsPage from "@/pages/widgets.page";
import DevToolsPage from "@/pages/dev-tools.page";
import DirectChatPage from "@/pages/direct-chat.page";

import WidgetsContentSectionPage from "@/pages/widgets/content-sections.page";
import WidgetsNewContentSectionPage from "@/pages/widgets/content-sections/new.content-sections.page";
import WidgetsEditContentSectionPage from "@/pages/widgets/content-sections/edit.content-sections.page";
import WidgetsShowContentSectionPage from "@/pages/widgets/content-sections/show.content-sections.page";
import WidgetsContentSectionNewBlockPage from "@/pages/widgets/content-sections/blocks/new.content-blocks.page";
import WidgetsContentSectionShowBlockPage from "@/pages/widgets/content-sections/blocks/show.content-blocks.page";
import WidgetsContentSectionEditBlockPage from "@/pages/widgets/content-sections/blocks/edit.content-blocks.page";

import WidgetsCountdownPage from "@/pages/widgets/countdowns.page";
import WidgetsNewCountdownPage from "@/pages/widgets/countdowns/new.countdowns.page";
import WidgetsEditCountdownPage from "@/pages/widgets/countdowns/edit.countdowns.page";
import WidgetsShowCountdownPage from "@/pages/widgets/countdowns/show.countdowns.page";

import WidgetsHtmlBlocksPage from "@/pages/widgets/html-blocks.page";
import WidgetsNewHtmlBlocksPage from "@/pages/widgets/html-blocks/new.html-blocks.page";
import WidgetsEditHtmlBlocksPage from "@/pages/widgets/html-blocks/edit.html-blocks.page";

import WidgetsCustomComponentsPage from "@/pages/dev-tools/custom-components.page";
import WidgetsNewCustomComponentPage from "@/pages/dev-tools/custom-components/new.custom-components.page";
import WidgetsEditCustomComponentPage from "@/pages/dev-tools/custom-components/edit.custom-components.page";

import WidgetsPresentationsPage from "@/pages/dev-tools/presentations.page";
import WidgetsNewPresentationPage from "@/pages/dev-tools/presentations/new.presentations.page";
import WidgetsEditPresentationPage from "@/pages/dev-tools/presentations/edit.presentations.page";
import WidgetsShowPresentationPage from "@/pages/dev-tools/presentations/show.presentations.page";

import WidgetsImageAssetsPage from "@/pages/dev-tools/image-library.page";
import WidgetsNewImageAssetPage from "@/pages/dev-tools/image-assets/new.image-assets.page";
import WidgetsEditImageAssetPage from "@/pages/dev-tools/image-assets/edit.image-assets.page";

import WidgetsListPage from "@/pages/widgets/lists.page";
import WidgetsNewListPage from "@/pages/widgets/lists/new.lists.page";
import WidgetsEditListPage from "@/pages/widgets/lists/edit.lists.page";
import WidgetsShowListPage from "@/pages/widgets/lists/show.lists.page";
import WidgetsNewListsEntriesPage from "@/pages/widgets/lists/entries/new.lists-entries.page";
import WidgetsEditListsEntriesPage from "@/pages/widgets/lists/entries/edit.lists-entries.page";
import WidgetsShowListsEntriesPage from "@/pages/widgets/lists/entries/show.lists-entries.page";

import WidgetsNotificationsPage from "@/pages/widgets/notifications.page";
import WidgetsNewNotificationPage from "@/pages/widgets/notifications/new.notifications.page";
import WidgetsEditNotificationPage from "@/pages/widgets/notifications/edit.notifications.page";
import WidgetsShowNotificationPage from "@/pages/widgets/notifications/show.notifications.page";

import WidgetsNewSubmissionContentPage from "@/pages/widgets/submission-contents/new.submission-contents.page";
import WidgetsEditSubmissionContentPage from "@/pages/widgets/submission-contents/edit.submission-contents.page";

import WidgetsSchedulesPage from "@/pages/widgets/schedules.page";
import WidgetsNewSchedulePage from "@/pages/widgets/schedules/new.schedules.page";
import WidgetsEditSchedulePage from "@/pages/widgets/schedules/edit.schedules.page";
import WidgetsShowSchedulePage from "@/pages/widgets/schedules/show.schedules.page";
import WidgetsNewScheduleItemPage from "@/pages/widgets/schedules/items/new.schedule-items.page";
import WidgetsEditScheduleItemPage from "@/pages/widgets/schedules/items/edit.schedule-items.page";
import WidgetsShowScheduleItemPage from "@/pages/widgets/schedules/items/show.schedule-items.page";

import WidgetsTextBlocksPage from "@/pages/widgets/text-blocks.page";
import WidgetsNewTextBlockPage from "@/pages/widgets/text-blocks/new.text-blocks.page";
import WidgetsEditTextBlockPage from "@/pages/widgets/text-blocks/edit.text-blocks.page";
import WidgetsShowTextBlockPage from "@/pages/widgets/text-blocks/show.text-blocks.page";

import WidgetsSubmissionFormPage from "@/pages/widgets/submission-forms.page";
import WidgetsNewSubmissionFormPage from "@/pages/widgets/submission-forms/new.submission-forms.page";
import WidgetsEditSubmissionFormPage from "@/pages/widgets/submission-forms/edit.submission-forms.page";
import WidgetsNewSubmissionPage from "@/pages/widgets/submission-forms/submissions/new.submission.page";
import WidgetsEditSubmissionPage from "@/pages/widgets/submission-forms/submissions/edit.submission.page";
import WidgetsExportSortSubmissionFormPage from "@/pages/widgets/submission-forms/export_sort.submission-forms.page";

import WidgetsRegistrationFormPage from "@/pages/widgets/registration-forms.page";
import WidgetsNewRegistrationFormPage from "@/pages/widgets/registration-forms/new.registration-forms.page";
import WidgetsEditRegistrationFormPage from "@/pages/widgets/registration-forms/edit.registration-forms.page";

import WidgetsVenueEventsListsPage from "@/pages/widgets/venue-events-lists.page";
import WidgetsNewVenueEventsListsPage from "@/pages/widgets/venue-events-lists/new.venue-events-lists.page";
import WidgetsEditVenueEventsListsPage from "@/pages/widgets/venue-events-lists/edit.venue-events-lists.page";

import UsersPage from "@/pages/users.page";

import UsersParticipantsPage from "@/pages/users/participants.page";
import UsersNewParticipantsPage from "@/pages/users/participants/new.participants.page";
import UsersNewParticipantRolesPage from "@/pages/users/participants/roles/new.participant-role.page";
import UsersEditParticipantRolesPage from "@/pages/users/participants/roles/edit.participant-role.page";
import UsersEditParticipantsPage from "@/pages/users/participants/edit.participants.page";
import UsersShowParticipantsPage from "@/pages/users/participants/show.participants.page";

import BlogArticlesPage from "@/pages/blog.page";
import NewBlogArticlePage from "@/pages/blog/new.blog.page";
import EditBlogArticlePage from "@/pages/blog/edit.blog.page";
import ShowBlogArticlePage from "@/pages/blog/show.blog.page";
import NewBlogTagPage from "@/pages/blog/tags/new.blog-tag.page";
import EditBlogTagPage from "@/pages/blog/tags/edit.blog-tag.page";

import DataLoaderPage from "@/pages/data-loader.page";
import NewDataLoaderSchemaPage from "@/pages/data-loader/new.data-loader.page";
import EditDataLoaderSchemaPage from "@/pages/data-loader/edit.data-loader.page";
import NewDataLoaderEntityPage from "@/pages/data-loader/entities/new.data-loader-entities.page";
import EditDataLoaderEntityPage from "@/pages/data-loader/entities/edit.data-loader-entities.page";

import GreenhouseApplicationPage from "@/pages/greenhouse/applications/applications.page";
import NewGreenhouseApplicationPage from "@/pages/greenhouse/applications/new.applications.page";
import EditGreenhouseApplicationPage from "@/pages/greenhouse/applications/edit.applications.page";

import GreenhouseJobPage from "@/pages/greenhouse/jobs/jobs.page";
import NewGreenhouseJobPage from "@/pages/greenhouse/jobs/new.jobs.page";

const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: ForgotPasswordPage,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "setPassword",
    path: "/set-password/:token",
    component: SetPasswordPage,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "register",
    path: "/register",
    component: RegisterPage,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: OnboardingPage,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: ":step",
      },
    ],
  },
  {
    name: "join",
    path: "/join/:token",
    component: JoinPage,
  },
  {
    name: "logs",
    path: "/logs",
    component: LogsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "directChat",
    path: "/direct-chat",
    component: DirectChatPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showLogs",
    path: "/logs/:id",
    component: ShowLogsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "projects",
    path: "/projects",
    component: ProjectsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "teamProjects",
    path: "/team/:id/projects",
    component: ProjectsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "settings",
    path: "/settings",
    component: SettingsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newProject",
    path: "/projects/new",
    component: NewProjectPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editProject",
    path: "/projects/:id/edit",
    component: EditProjectPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newTeam",
    path: "/teams/new",
    component: NewTeamPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editTeam",
    path: "/teams/:id/edit",
    component: EditTeamPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editTeamMember",
    path: "/teams/members/:id/edit",
    component: EditTeamMemberPage,
    beforeEnter: ifSuperAdmin,
  },

  {
    name: "addons",
    path: "/addons",
    component: AddonsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "dashboard",
    path: "/",
    component: DashboardPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "themes",
    path: "/themes",
    component: ThemePage,
    beforeEnter: ifAuthenticated,
    children: [{ path: ":id" }],
  },
  {
    name: "themeTemplate",
    path: "/theme-templates",
    component: ThemeTemplatePage,
    beforeEnter: ifAuthenticated,
    children: [{ path: ":id" }],
  },
  {
    name: "pages",
    path: "/pages",
    component: PagesPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newPage",
    path: "/pages/new",
    component: NewPagePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "ShowPagePage",
    path: "/pages/:id",
    component: ShowPagePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "EditPagePage",
    path: "/pages/:id/edit",
    component: EditPagePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newEvent",
    path: "/events/new",
    component: NewEventPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "ShowEventPage",
    path: "/events/:id",
    component: ShowEventPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "EditEventPage",
    path: "/events/:id/edit",
    component: EditEventPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "events",
    path: "/events",
    component: EventsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newVenueEvent",
    path: "/venue-events/new",
    component: NewVenueEventPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "EditVenueEventPage",
    path: "/venue-events/:id/edit",
    component: EditVenueEventPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "venue-events",
    path: "/venue-events",
    component: VenueEventsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "EditTicketmasterVenuePage",
    path: "/ticketmaster-venues/:id/edit",
    component: EditTicketmasterVenuePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "ticketmaster-venues",
    path: "/ticketmaster-venues",
    component: TicketmasterVenuesPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "devTools",
    path: "/dev-tools",
    component: DevToolsPage,
    beforeEnter: ifAuthenticated,
    children: [
      { path: "presentations", component: WidgetsPresentationsPage },
      { path: "presentations/new", component: WidgetsNewPresentationPage },
      {
        path: "presentations/:id/edit",
        component: WidgetsEditPresentationPage,
      },
      {
        path: "presentations/:id",
        component: WidgetsShowPresentationPage,
      },
      { path: "custom-components", component: WidgetsCustomComponentsPage },
      {
        path: "custom-components/new",
        component: WidgetsNewCustomComponentPage,
      },
      {
        path: "custom-components/:id/edit",
        component: WidgetsEditCustomComponentPage,
      },
      { path: "image-library", component: WidgetsImageAssetsPage },
      {
        path: "image-assets/new",
        component: WidgetsNewImageAssetPage,
      },
      {
        path: "image-assets/:id/edit",
        component: WidgetsEditImageAssetPage,
      },
    ],
  },
  {
    name: "widgets",
    path: "/widgets",
    component: WidgetsPage,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "content-sections",
        component: WidgetsContentSectionPage,
      },
      {
        path: "content-sections/new",
        component: WidgetsNewContentSectionPage,
      },
      {
        path: "content-sections/:id/edit",
        component: WidgetsEditContentSectionPage,
      },
      {
        path: "content-sections/:id",
        component: WidgetsShowContentSectionPage,
      },
      {
        path: "content-sections/:id/blocks/new",
        component: WidgetsContentSectionNewBlockPage,
      },
      {
        path: "content-sections/:contentSectionId/blocks/:id",
        component: WidgetsContentSectionShowBlockPage,
      },
      {
        path: "content-sections/:contentSectionId/blocks/:id/edit",
        component: WidgetsContentSectionEditBlockPage,
      },

      {
        path: "submission-contents/new",
        component: WidgetsNewSubmissionContentPage,
      },
      {
        path: "submission-contents/:id/edit",
        component: WidgetsEditSubmissionContentPage,
      },
      { path: "countdowns", component: WidgetsCountdownPage },
      { path: "countdowns/new", component: WidgetsNewCountdownPage },
      { path: "countdowns/:id/edit", component: WidgetsEditCountdownPage },
      { path: "countdowns/:id", component: WidgetsShowCountdownPage },
      { path: "html-blocks", component: WidgetsHtmlBlocksPage },
      { path: "html-blocks/new", component: WidgetsNewHtmlBlocksPage },
      { path: "html-blocks/:id/edit", component: WidgetsEditHtmlBlocksPage },

      { path: "lists", component: WidgetsListPage },
      { path: "lists/new", component: WidgetsNewListPage },
      { path: "lists/:id/edit", component: WidgetsEditListPage },
      { path: "lists/:id", component: WidgetsShowListPage },
      {
        path: "lists/:listId/entries/:id/edit",
        component: WidgetsEditListsEntriesPage,
      },
      {
        path: "lists/:listId/entries/new",
        component: WidgetsNewListsEntriesPage,
      },
      {
        path: "lists/:listId/entries/:id",
        component: WidgetsShowListsEntriesPage,
      },

      { path: "notifications", component: WidgetsNotificationsPage },
      { path: "notifications/new", component: WidgetsNewNotificationPage },
      {
        path: "notifications/:id/edit",
        component: WidgetsEditNotificationPage,
      },
      { path: "notifications/:id", component: WidgetsShowNotificationPage },

      { path: "schedules", component: WidgetsSchedulesPage },
      { path: "schedules/new", component: WidgetsNewSchedulePage },
      { path: "schedules/:id/edit", component: WidgetsEditSchedulePage },
      { path: "schedules/:id", component: WidgetsShowSchedulePage },
      {
        path: "schedules/:scheduleId/items/new",
        component: WidgetsNewScheduleItemPage,
      },
      {
        path: "schedules/:scheduleId/items/:id/edit",
        component: WidgetsEditScheduleItemPage,
      },
      {
        path: "schedules/:scheduleId/items/:id",
        component: WidgetsShowScheduleItemPage,
      },

      { path: "text-blocks", component: WidgetsTextBlocksPage },
      { path: "text-blocks/new", component: WidgetsNewTextBlockPage },
      { path: "text-blocks/:id/edit", component: WidgetsEditTextBlockPage },
      { path: "text-blocks/:id", component: WidgetsShowTextBlockPage },

      {
        path: "submission-forms",
        component: WidgetsSubmissionFormPage,
      },
      {
        path: "submission-forms/new",
        component: WidgetsNewSubmissionFormPage,
      },
      {
        path: "submission-forms/:id/edit",
        component: WidgetsEditSubmissionFormPage,
      },
      {
        path: "submission-forms/:id/export_sort",
        component: WidgetsExportSortSubmissionFormPage,
      },
      {
        path: "submission-forms/:submissionFormId/submissions/new",
        component: WidgetsNewSubmissionPage,
      },
      {
        path: "submission-forms/:submissionFormId/submissions/:id/edit",
        component: WidgetsEditSubmissionPage,
      },

      {
        path: "registration-forms",
        component: WidgetsRegistrationFormPage,
      },
      {
        path: "registration-forms/new",
        component: WidgetsNewRegistrationFormPage,
      },
      {
        path: "registration-forms/:id/edit",
        component: WidgetsEditRegistrationFormPage,
      },
      { path: "venue-events-lists", component: WidgetsVenueEventsListsPage },
      {
        path: "venue-events-lists/new",
        component: WidgetsNewVenueEventsListsPage,
      },
      {
        path: "venue-events-lists/:id/edit",
        component: WidgetsEditVenueEventsListsPage,
      },
    ],
  },
  {
    name: "users",
    path: "/users",
    component: UsersPage,
    beforeEnter: ifAuthenticated,
    children: [
      { path: "participants", component: UsersParticipantsPage },
      { path: "participants/new", component: UsersNewParticipantsPage },
      {
        path: "participants/roles/new",
        component: UsersNewParticipantRolesPage,
      },
      {
        path: "participants/roles/:id/edit",
        component: UsersEditParticipantRolesPage,
      },
      { path: "participants/:id/edit", component: UsersEditParticipantsPage },
      { path: "participants/:id", component: UsersShowParticipantsPage },
    ],
  },
  {
    name: "variables",
    path: "/variables",
    component: VariablesPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newVariable",
    path: "/variables/new",
    component: NewVariablePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editVariable",
    path: "/variables/:id/edit",
    component: EditVariablePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showVariable",
    path: "/variables/find-or-create",
    component: FindOrCreateVariablePage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "blog-articles",
    path: "/blog",
    component: BlogArticlesPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newBlogArticle",
    path: "/blog-articles/new",
    component: NewBlogArticlePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editBlogArticle",
    path: "/blog-articles/:id/edit",
    component: EditBlogArticlePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showBlogArticle",
    path: "/blog-articles/:id",
    component: ShowBlogArticlePage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "newBlogTag",
    path: "/blog-tags/new",
    component: NewBlogTagPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editBlogTag",
    path: "/blog-tags/:id/edit",
    component: EditBlogTagPage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "chats",
    path: "/chats",
    component: ChatsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newChat",
    path: "/chats/new",
    component: NewChatPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editChat",
    path: "/chats/:id/edit",
    component: EditChatPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showChat",
    path: "/chats/:id",
    component: ShowChatPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editMessage",
    path: "/chats/:chatId/messages/:id/edit",
    component: EditChatMessagePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showMessage",
    path: "/chats/:chatId/messages/:id",
    component: ShowChatMessagePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "support",
    path: "/support",
    component: SupportPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newSupport",
    path: "/support/new",
    component: NewSupportPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editSupport",
    path: "/support/:id/edit",
    component: EditSupportPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showSupport",
    path: "/support/:id",
    component: ShowSupportPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "showSupportTicketPage",
    path: "/support/:supportId/tickets/:id",
    component: ShowSupportTicketPage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "greenhouseApplicationPage",
    path: "/greenhouse/applications",
    component: GreenhouseApplicationPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newGreenhouseApplicationPage",
    path: "/greenhouse/applications/new",
    component: NewGreenhouseApplicationPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editGreenhouseApplicationPage",
    path: "/greenhouse/applications/:id/edit",
    component: EditGreenhouseApplicationPage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "greenhouseJobPage",
    path: "/greenhouse/jobs",
    component: GreenhouseJobPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newGreenhouseJobPage",
    path: "/greenhouse/jobs/new",
    component: NewGreenhouseJobPage,
    beforeEnter: ifAuthenticated,
  },

  {
    name: "dataLoaderPage",
    path: "/content-management",
    component: DataLoaderPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newDataLoaderSchemaPage",
    path: "/content-management/new",
    component: NewDataLoaderSchemaPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editDataLoaderSchemaPage",
    path: "/content-management/:id/edit",
    component: EditDataLoaderSchemaPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "newDataLoaderEnititySchemaPage",
    path: "/content-management/:schema_id/entities/new",
    component: NewDataLoaderEntityPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "editDataLoaderEnititySchemaPage",
    path: "/content-management/:schema_id/entities/:id/edit",
    component: EditDataLoaderEntityPage,
    beforeEnter: ifAuthenticated,
  },
];
export default routes;
