<template>
  <div class="submit-footer">
    <v-btn
      :disabled="inProgress || disabled"
      @click="submitCallback"
      color="primary"
      elevation="0"
      rounded
      large
      style="min-width: 140px"
    >
      <slot v-if="!inProgress"></slot>
      <div v-else>
        <v-progress-circular
          :value="progress"
          :size="35"
          :width="3"
          :rotate="-90"
          style="margin-right: 5px"
        >
          <span style="font-size: 10px; letter-spacing: 0">
            {{ progress }}%
          </span>
        </v-progress-circular>
        Uploading...
      </div>
    </v-btn>

    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  props: {
    submit: Function,
    refs: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inProgress: false,
      progress: 0,
    };
  },
  methods: {
    async submitCallback() {
      const invalid = this.refs.form && !this.refs.form.validate();

      if (invalid) {
        this.scrollToInvalidInput(this.refs.form.inputs);

        return;
      }

      try {
        this.inProgress = true;
        await this.submit({
          onUploadProgress: (progress) => {
            console.log(progress);
            this.progress = progress;
          },
        });
      } finally {
        this.inProgress = false;
        this.progress = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.submit-footer {
  background: white;
  padding: 20px 40px;
  border: 2px solid var(--v-border-base);
  border-top: none;
  border-radius: 0 0 10px 10px;
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .v-btn {
    margin-right: 15px;
  }
}
</style>
