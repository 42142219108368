<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Details" v-if="!edit">
        <FormRow label="Job Post">
          <template #tooltip>
            Optional. If entities in this schema will be shown on a dynamic page
            (with a url like "/work/:work_name", where ":work_name" is replaced
            by the entity's tech name), select that page here.
          </template>
          <GreenhouseJobPostsSelector
            v-model="form.greenhouse_job_post_id"
            :condition="(job_post) => job_post.active"
            @updated="jobPostSelected"
          ></GreenhouseJobPostsSelector>
        </FormRow>

        <FormRow label="Page">
          <template #tooltip>
            Optional. If entities in this schema will be shown on a dynamic page
            (with a url like "/work/:work_name", where ":work_name" is replaced
            by the entity's tech name), select that page here.
          </template>
          <PagesSelector v-model="form.page_id"></PagesSelector>
        </FormRow>
      </FormPanel>

      <FormPanel label="Application Builder" v-if="edit" readonly>
        <template v-if="activeJobPost">
          <ApplicationBuilder
            ref="applicationBuilder"
            :active-job-post="activeJobPost"
            v-model="form.application"
          >
          </ApplicationBuilder>

          <!-- Validation Message -->
          <div
            v-if="validationMessage"
            :class="['validation-message', validationStatus]"
          >
            {{ validationMessage }}
          </div>

          <!-- Optional Preview Section -->
          <div class="actions-panel">
            <button
              @click="previewApplication"
              type="button"
              class="preview-btn"
            >
              Preview Application
            </button>
          </div>

          <!-- Application Configuration Preview -->
          <div v-if="showPreview" class="preview-panel">
            <h3>Application Configuration Preview</h3>
            <pre>{{ JSON.stringify(form.application, null, 2) }}</pre>
          </div>
        </template>
        <div v-else class="loading-message">Loading job post data...</div>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="validateAndSubmit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import ApplicationBuilder from "./application.builder.vue";
export default {
  extends: mixin,
  components: {
    ApplicationBuilder,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationMessage: "",
      validationStatus: "",
      showPreview: false,
      activeJobPost: null,
      // refreshInProgress: false,
      // refreshError: "",
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async refreshJob() {
      this.refreshInProgress = true;

      try {
        console.log("refreshJob called");
        await this.sleep(1000); // simulate refresh logic
      } catch (error) {
        console.error("An error occurred:", error.message);
      } finally {
        this.refreshInProgress = false;
      }
    },
    jobPostSelected(jobPost) {
      this.activeJobPost = jobPost;

      // Initialize form.application if it doesn't exist
      if (!this.form.application) {
        this.form.application = { steps: [] };
      }
    },

    previewApplication() {
      this.showPreview = !this.showPreview;
    },

    // Validate application builder before submitting
    validateAndSubmit() {
      // If in edit mode and we have an application builder
      if (this.edit && this.$refs.applicationBuilder) {
        const isValid = this.$refs.applicationBuilder.validate();

        if (!isValid) {
          this.validationMessage =
            "Please add all required questions to an application step";
          this.validationStatus = "error";
          return false;
        } else {
          this.validationMessage = "Application structure is valid!";
          this.validationStatus = "success";
        }
      }

      // Proceed with the standard submit logic from mixin
      return this.submit();
    },

    async setJobPost() {
      try {
        const { data } = await this.$api.GreenhouseJobPost.get({
          id: this.form.greenhouse_job_post_id,
        });

        this.activeJobPost = data.find(
          (post) => post.id === this.form.greenhouse_job_post_id
        );

        if (this.activeJobPost) {
          this.activeJobPost.questions.forEach((question) => {
            question["questionSource"] = "greenhouse";
          });
        }

        // Initialize form.application if needed
        if (!this.form.application) {
          this.form.application = { steps: [] };
        }

        console.log("Active job post set:", this.activeJobPost);
      } catch (error) {
        console.error("Error loading job post:", error);
      }
    },
  },
  async created() {
    console.info("this.form", JSON.stringify(this.form));

    // Initialize form.application if it doesn't exist
    if (!this.form.application) {
      this.form.application = { steps: [] };
    }

    if (this.edit && this.form.greenhouse_job_post_id) {
      await this.setJobPost();
    }
  },
};
</script>

<style scoped lang="scss">
.schema-field {
  padding: 15px;
  border: 2px solid;
  margin-bottom: 10px;
  position: relative;

  & .form-row,
  &::v-deep .form-row {
    max-width: calc(100% - 100px);
  }

  &__btns {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-menu {
    display: flex;
    align-items: center;
    max-width: calc(100% - 100px);

    & > * {
      margin: 0 10px;
    }
  }

  &-component {
    padding: 10px;
    max-width: 550px;
  }
}

.validation-message {
  padding: 10px 15px;
  border-radius: 4px;
  margin: 15px 0;
}

.validation-message.success {
  background-color: #e8f5e9;
  border: 1px solid #a5d6a7;
  color: #2e7d32;
}

.validation-message.error {
  background-color: #ffebee;
  border: 1px solid #ef9a9a;
  color: #c62828;
}

.actions-panel {
  margin: 20px 0;
}

.preview-btn {
  background-color: #607d8b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.preview-panel {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.preview-panel pre {
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  font-family: monospace;
}

.loading-message {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
</style>
