<template>
  <div class="new-jobs-page">
    <div class="page-title">Add Tracked Job</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="create"
      btnText="Add Tracked Job"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.jobs.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.GreenhouseJob.new();
      this.form = data;
      console.info("data", data);
    },
    async create() {
      await this.$api.GreenhouseJob.create(this.form);
      this.$router.push("/greenhouse/jobs");
    },
  },
};
</script>

<style lang="scss">
.new-jobs-page {
  &::v-deep .v-expansion-panel-header__icon {
    display: none;
  }
}
</style>
