<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Theme Details">
        <FormRow
          required
          label="Theme Name"
          tooltip="Give a name to your custom theme."
        >
          <TextField v-model="form.title" />
        </FormRow>
        <FormRow
          required
          label="Background Color"
          tooltip="The Background color dictates the color of your Commons site’s background. "
        >
          <ColorPicker v-model="form.body_background" />
        </FormRow>
        <FormRow
          required
          label="Highlight Color"
          tooltip="The Highlight color dictates the button, link, borders, some graphic elements, and large headline color."
        >
          <template #label>Highlight Color</template>
          <template #additional>Buttons, inputs, etc</template>

          <ColorPicker v-model="form.primary_color" />
        </FormRow>
        <FormRow
          required
          label="Text Color"
          tooltip="The Text color dictates the color of any text that’s not in your Header or Footer."
        >
          <ColorPicker v-model="form.body_color" />
        </FormRow>

        <FormRow
          required
          label="Title Font Details (H1)"
          tooltip="The Title Font allows you to choose the font and it’s accompanying parameters that will appear as headlines on your Commons site."
        >
          <FontSelector v-model="form.h1_font" />
        </FormRow>
        <FormRow
          required
          label="Subtitle Font Details (H2)"
          tooltip="The Subtitle Font allows you to choose the font and it’s accompanying parameters that will appear as subtitles on your Commons site."
        >
          <FontSelector v-model="form.h2_font" />
        </FormRow>

        <FormRow
          required
          label="Body Text Font"
          tooltip="Body Text Font allows you to choose the font and it’s accompanying parameters that will appear as body text on your Commons site."
        >
          <FontSelector v-model="form.paragraph_font" />
        </FormRow>
      </FormPanel>
      <FormPanel label="Header Details">
        <FormRow
          tooltip="The Wide Logo is your brand, company, or University’s logo. It will appear on the Header on the far left in a desktop browser."
        >
          <template #label>Wide Logo (for Desktop)<br />(300x100)</template>
          <Uploader
            size="contain"
            :ratio="300 / 100"
            v-model="form.header_logo"
          />
        </FormRow>
        <FormRow
          tooltip="The Square Logo is your brand, company, or University’s logo. It will appear on the Header on the far left of a mobile browser."
        >
          <template #label>Square Logo (for Mobile)<br />(200x200)</template>
          <Uploader :ratio="100 / 100" v-model="form.header_logo_mobile" />
        </FormRow>
        <FormRow
          required
          label="Header Background Color"
          tooltip="The Header Background color dictates the color of your Commons site’s header. "
        >
          <ColorPicker v-model="form.header_background" />
        </FormRow>
        <FormRow
          required
          label="Header Text Color"
          tooltip="The Header Text Color dictates the color of any text that’s in your header."
        >
          <ColorPicker v-model="form.header_color" />
        </FormRow>
        <FormRow
          required
          label="Header Text Font"
          tooltip="The Header Text Font allows you to choose the font and it’s accompanying parameters that will appear as header text on your Commons site."
        >
          <FontSelector v-model="form.header_font" />
        </FormRow>

        <FormRow required>
          <template #label>Header Dropdown Menu</template>
          <template #additional>Default Text</template>

          <TextField v-model="form.header_dropdown_placeholder" />
        </FormRow>

        <FormRow>
          <template #label>Collapse header on scroll</template>
          <Checkbox v-model="form.collapse_app_bar_on_scroll" />
        </FormRow>
        <FormRow
          tooltip="If enabled, the header will disappear when users scroll down and reappear when they scroll up."
        >
          <template #label>Hide header on scroll</template>
          <Checkbox v-model="form.hide_nav_bar_on_scroll" />
        </FormRow>
        <FormRow
          tooltip="By default, submenus in the header navigation appear on hover. Check this to require a click instead."
        >
          <template #label>Open submenus on click</template>
          <Checkbox v-model="form.submenus_open_on_click" />
        </FormRow>
        <FormRow
          tooltip="By default, submenus in the header navigation stay open for 1 second after a mouse leaves, for accessibility purposes. (See https://www.w3.org/WAI/tutorials/menus/flyout/#mouse-users.) If for some reason this site’s menu design can’t support thath, you can disable it here."
        >
          <template #label>Disable submenu close delay</template>
          <Checkbox v-model="form.disable_close_delay" />
        </FormRow>
        <FormRow>
          <template #label
            >Custom breakpoint for hamburger menu (default 960)</template
          >
          <TextField v-model="form.custom_hamburger_breakpoint" />
        </FormRow>
        <FormRow>
          <template #label>Sidebar Submenu Dropdowns</template>
          <Checkbox v-model="form.sidebar_submenu_dropdowns" />
        </FormRow>
        <FormRow>
          <template #label>Sidebar Expansion Panels for Submenus</template>
          <Checkbox v-model="form.sidebar_expansion_panels" />
        </FormRow>
      </FormPanel>

      <FormPanel label="Footer Details">
        <FormRow
          tooltip="The Wide Logo is your brand, company, or University’s logo. It will appear in the Footer on the far left of a desktop browser, and centered in a mobile browser."
        >
          <template #label>Wide Logo (for Footer)</template>
          <Uploader
            size="contain"
            :ratio="300 / 100"
            v-model="form.footer_logo"
          />
        </FormRow>
        <FormRow
          required
          label="Footer Background Color"
          tooltip="The Footer Background color dictates the color of your Commons site’s footer."
        >
          <ColorPicker v-model="form.footer_background" />
        </FormRow>
        <FormRow
          required
          label="Footer Text Color"
          tooltip="The Footer Text Color dictates the color of any text that’s in your footer."
        >
          <ColorPicker v-model="form.footer_color" />
        </FormRow>

        <FormRow
          required
          label="Footer Text Font"
          tooltip="The Footer Text Font allows you to choose the font and it’s accompanying parameters that will appear as footer text on your Commons site."
        >
          <FontSelector v-model="form.footer_font" />
        </FormRow>
      </FormPanel>

      <FormPanel label="Default Images">
        <FormRow
          tooltip="The Favicon is the icon or logo that appears next to your Commons site title in a browser or bookmark list."
        >
          <template #label>Favicon<br />(120x120)</template>
          <Uploader :ratio="1" v-model="form.favicon" />
        </FormRow>
        <FormRow>
          <template #label>Splash Screen Image</template>
          <Uploader :ratio="320 / 180" contain v-model="form.splash_screen" />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for an Event."
        >
          <template #label
            >Default "Event" Background Image<br />(1200x600)</template
          >
          <Uploader :ratio="1200 / 600" v-model="form.event_background" />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear in the background of a Timeline event's thumbnail."
        >
          <template #label
            >Default "Timeline" Thumbnail<br />(580x320)</template
          >
          <Uploader :ratio="580 / 320" v-model="form.event_preview" />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for a Content Block."
        >
          <template #label
            >Default "Content Block" Thumbnail<br />(580x320)</template
          >
          <Uploader :ratio="580 / 320" v-model="form.content_block_preview" />
        </FormRow>
        <FormRow
          tooltip="The default image that will appear when you do not upload a specific image for a Schedule item."
        >
          <template #label
            >Default "Schedule" Backdrop<br />(1280x300)</template
          >
          <Uploader :ratio="1280 / 300" v-model="form.schedule_preview" />
        </FormRow>
      </FormPanel>
      <FormPanel label="Custom Styles (CSS)">
        <small>
          <b>{{ form.title || "theme" }}.css</b>
        </small>
        <CodeEditor v-model="form.css" />
      </FormPanel>
      <FormPanel label="Other">
        <FormRow
          required
          label="Custom Sticky Video Scroll Position (default: 300)"
          tooltip="If you're using “Video follows user on scroll” but need to adjust the scroll position that triggers the fixed video, change it here."
        >
          <TextField v-model="form.sticky_video_scroll_trigger" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :submit="submit" :refs="$refs">
      Update Theme
      <template #actions>
        <v-btn @click="showTemplateModal = true" rounded outlined
          >Create Template From The Theme</v-btn
        >
      </template>
    </SubmitFooter>
    <v-dialog max-width="420" v-model="showTemplateModal">
      <v-card>
        <ThemeTemplate :theme="form" />
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  props: {
    value: Object,
    submit: Function,
  },
  data() {
    return {
      form: { ...this.value },
      showTemplateModal: false,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
    form: {
      deep: true,
      handler() {
        if (JSON.stringify(this.form) != JSON.stringify(this.value)) {
          this.$emit("input", { ...this.form });
        }
      },
    },
  },
};
</script>

<style></style>
