<template>
  <div class="application-builder">
    <!-- Validation Error Messages -->
    <div v-if="validationErrors.length > 0" class="validation-errors">
      <h3>Required Questions Missing</h3>
      <p>
        The following required questions must be added to an application step:
      </p>
      <ul>
        <li v-for="(question, index) in validationErrors" :key="index">
          {{ question.label }} ({{ question.type }})
        </li>
      </ul>
    </div>

    <!-- Application Steps Section -->
    <div class="application-steps">
      <div class="steps-header">
        <h3>Application Steps</h3>
        <div class="actions">
          <button
            @click="addApplicationStep"
            type="button"
            class="add-step-btn"
          >
            Add Application Step
          </button>
          <button @click="checkValidation" type="button" class="validate-btn">
            Check Required Fields
          </button>
        </div>
      </div>

      <!-- List of Application Steps -->
      <div
        v-for="(step, stepIndex) in applicationSteps"
        :key="stepIndex"
        class="application-step"
      >
        <div class="step-header">
          <input
            v-model="step.title"
            placeholder="Step Title"
            class="step-title-input"
          />
          <button
            @click="removeApplicationStep(stepIndex)"
            type="button"
            class="remove-btn"
          >
            Remove Step
          </button>
        </div>

        <!-- Questions assigned to this step -->
        <div class="step-questions">
          <h4>Step Questions</h4>
          <div
            v-for="(question, questionIndex) in step.questions"
            :key="questionIndex"
            class="assigned-question"
          >
            <div class="question-card">
              <div class="question-header">
                <div class="d-flex" style="gap: 10px; flex: 1; min-width: 0">
                  <h4 class="truncate-text">{{ question.label }}</h4>
                  <!-- <span class="question-type">{{ question.type }}</span> -->
                  <span class="question-type">{{ question.name }}</span>
                </div>
                <div class="d-flex" style="gap: 5px">
                  <button
                    @click="removeQuestionFromStep(stepIndex, questionIndex)"
                    type="button"
                    class="remove-btn"
                  >
                    Remove
                  </button>
                  <v-icon @click="moveQuestion(stepIndex, questionIndex, 'up')">
                    mdi-arrow-up
                  </v-icon>
                  <v-icon
                    @click="moveQuestion(stepIndex, questionIndex, 'down')"
                  >
                    mdi-arrow-down
                  </v-icon>
                </div>
              </div>
              <div class="question-content">
                <label>General</label>

                <template v-if="question.type === 'custom_html'">
                  <div class="form-group">
                    <v-textarea
                      v-model="question.description"
                      label="Content (supports HTML)"
                      @change="updateApplicationData"
                      class="mt-3"
                      auto-grow
                      outlined
                    ></v-textarea>
                  </div>
                </template>

                <template v-else-if="question.type === 'schools'">
                  <!-- <div class="form-group">
                    <v-textarea
                      v-model="question.description"
                      label="Content (supports HTML)"
                      @change="updateApplicationData"
                      class="mt-3"
                      auto-grow
                      outlined
                    ></v-textarea>
                  </div> -->

                  <template v-if="question.extraOptions.length > 0">
                    <label>Options</label>
                    <div class="form-options">
                      <div
                        v-for="(option, option_i) in question.extraOptions"
                        class="form-option"
                        :key="`${question.name}-${option_i}`"
                      >
                        <!-- <label>{{ option.name }}</label> -->
                        <!-- <p>{{ JSON.stringify(option) }}</p> -->
                        <template v-if="option.type === 'checkbox'">
                          <v-checkbox
                            v-model="option.value"
                            :label="`${option.name} (${option.description})`"
                            class="mt-1"
                            hide-details="auto"
                          ></v-checkbox>
                        </template>
                        <template v-if="option.type === 'select'">
                          <v-select
                            v-model="option.value"
                            :items="option.values"
                            :label="option.name"
                            outlined
                            :class="{ 'mt-3': option_i === 0 }"
                            hide-details="auto"
                          ></v-select>
                        </template>
                        <template v-if="option.type === 'number'">
                          <v-text-field
                            v-model="option.value"
                            :label="option.name"
                            type="number"
                            outlined
                            hide-details="auto"
                          />
                        </template>
                      </div>
                    </div>
                  </template>
                </template>

                <template v-else>
                  <div class="form-group">
                    <v-text-field
                      v-model="question.label"
                      label="Question Label"
                      class="mt-3"
                      @change="updateApplicationData"
                      hide-details
                      outlined
                    />
                  </div>
                  <div class="form-group">
                    <v-textarea
                      v-model="question.description"
                      label="Question Description"
                      @change="updateApplicationData"
                      auto-grow
                      outlined
                      hide-details="auto"
                    ></v-textarea>
                  </div>

                  <label>Options</label>
                  <div class="form-options">
                    <div
                      v-for="(option, option_i) in question.extraOptions"
                      class="form-option"
                      :key="`${question.name}-${option_i}`"
                    >
                      <!-- <label>{{ option.name }}</label> -->
                      <!-- <p>{{ JSON.stringify(option) }}</p> -->
                      <template v-if="option.type === 'checkbox'">
                        <v-checkbox
                          v-model="option.value"
                          :label="`${option.name} (${option.description})`"
                          class="mt-1"
                          hide-details="auto"
                        ></v-checkbox>
                      </template>
                      <template v-if="option.type === 'select'">
                        <v-select
                          v-model="option.value"
                          :items="option.values"
                          :label="option.name"
                          outlined
                          :class="{ 'mt-3': option_i === 0 }"
                          hide-details="auto"
                        ></v-select>
                      </template>
                      <template v-if="option.type === 'number'">
                        <v-text-field
                          v-model="option.value"
                          :label="option.name"
                          type="number"
                          outlined
                          hide-details="auto"
                        />
                      </template>
                      <template v-if="option.type === 'text'">
                        <v-text-field
                          v-model="option.value"
                          :label="option.name"
                          outlined
                          hide-details="auto"
                        />
                      </template>
                    </div>
                  </div>

                  <div class="question-properties">
                    <div class="property">
                      <span class="property-label">Required: </span>
                      <span class="property-value">
                        {{ question.required === true ? "Yes" : "No" }}
                      </span>
                    </div>
                    <div class="property">
                      <span class="property-label">Private: </span>
                      <span class="property-value">
                        {{ question.private === true ? "Yes" : "No" }}
                      </span>
                    </div>
                    <div class="property">
                      <span class="property-label">Type: </span>
                      <span class="property-value">{{ question.type }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- Add Question to Step -->
          <div class="add-question-section">
            <button
              @click="showQuestionSelector(stepIndex)"
              type="button"
              class="add-question-btn"
            >
              Add Question
            </button>
            <button
              @click="addText(stepIndex)"
              type="button"
              class="add-question-btn"
            >
              Add Text
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Question Selector Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Select a Question</h3>
          <button @click="hideQuestionSelector" type="button" class="close-btn">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <input
            v-model="questionSearch"
            placeholder="Search questions..."
            class="search-input"
          />
          <div class="available-questions">
            <div
              v-for="question in availableQuestions"
              :key="question.name"
              @click="selectQuestion(question)"
              class="question-item"
              :class="{ unavailable: !isQuestionAvailable(question) }"
            >
              <span class="question-label">{{ question.label }}</span>
              <span class="question-type">{{ question.type }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ApplicationBuilder",
  props: {
    activeJobPost: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({ steps: [] }),
    },
  },
  data() {
    return {
      applicationSteps: [],
      selectedStepIndex: null,
      showModal: false,
      questionSearch: "",
      usedQuestionNames: new Set(),
      validationErrors: [],
      customQuestionTypeOptions: [
        {
          multi_select: [
            {
              name: "Select Type",
              type: "select",
              values: ["Dropdown", "Checkbox", "Chips"],
              default: "Dropdown",
            },
            {
              name: "Clearable",
              description:
                "Adds an X icon to filled input fields that will clear the field when clicked",
              type: "checkbox",
              default: false,
            },
            {
              name: "Wide",
              description:
                "Makes this question take up a whole row instead of half",
              type: "checkbox",
              default: false,
            },
          ],
        },
        {
          single_select: [
            {
              name: "Wide",
              description:
                "Makes this question take up a whole row instead of half",
              type: "checkbox",
              default: false,
            },
            {
              name: "Select Type",
              type: "select",
              values: ["Dropdown", "Autocomplete"],
              default: "Dropdown",
            },
          ],
        },
        {
          short_text: [
            {
              name: "Wide",
              description:
                "Makes this question take up a whole row instead of half",
              type: "checkbox",
              default: false,
            },
            {
              name: "Input Type",
              description: "",
              type: "select",
              values: ["", "Phone", "Email"],
              default: "",
            },
            {
              name: "Minimum Characters",
              description:
                "The minimum number of characters applicants must type for this field",
              type: "number",
              default: null,
            },
            {
              name: "Maximum Characters",
              description:
                "The maximum number of characters applicants can type for this field",
              type: "number",
              default: null,
            },
          ],
        },
        {
          long_text: [
            {
              name: "Wide",
              description:
                "Makes this question take up a whole row instead of half",
              type: "checkbox",
              default: true,
            },
          ],
        },
        { yes_no: [] },
        { currency: [] },
        { currency_range: [] },
        { number: [] },
        { number_range: [] },
        { date: [] },
        { url: [] },
        {
          attachment: [
            {
              name: "Acceptable Filetypes",
              description:
                "The types of files that can be uploaded for this question",
              type: "text",
              default:
                "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/rtf",
            },
          ],
        },
      ],
    };
  },
  computed: {
    customQuestions() {
      return [
        {
          description: "",
          extraOptions: [],
          label: "Schools",
          name: "schools",
          required: true,
          private: false,
          type: "schools",
          isCustomType: true,
        },
      ];
    },
    allJobPostQuestions() {
      // Combine the custom questions with the active job post questions
      return [...this.customQuestions, ...this.activeJobPost.questions];
    },
    availableQuestions() {
      const searchTerm = this.questionSearch.toLowerCase();
      return this.allJobPostQuestions.filter((question) => {
        return question.label.toLowerCase().includes(searchTerm);
      });
    },
  },
  watch: {
    // Watch for changes in activeJobPost to reinitialize
    activeJobPost: {
      handler(newValue) {
        if (newValue && newValue.questions) {
          this.initializeFromExistingData();
        }
      },
      deep: true,
    },
    // Watch for changes in value from parent (form.application)
    value: {
      handler(newValue) {
        if (
          newValue &&
          JSON.stringify(newValue) !==
            JSON.stringify(this.getApplicationDataForModel())
        ) {
          this.initializeFromExistingData();
        }
      },
      deep: true,
    },
    // Watch for changes in application steps to update parent
    applicationSteps: {
      handler() {
        this.updateApplicationData();
      },
      deep: true,
    },
  },
  created() {
    // Initialize from existing application data
    this.initializeFromExistingData();
  },
  methods: {
    getDefaultExtraOptions(type) {
      // Find the option object for this type
      const optionObj = this.customQuestionTypeOptions.find(
        (option) => Object.keys(option)[0].toLowerCase() === type.toLowerCase()
      );

      if (!optionObj) {
        return [];
      }

      // Get the options array for this type
      const options = optionObj[type] || [];

      // // Convert to default values object
      // const defaults = {};
      // options.forEach((option) => {
      //   defaults[option.name] = option.default;
      // });

      let defaults = [];
      options.forEach((option) => {
        defaults.push({ ...option, value: option.default });
      });

      return defaults;
    },
    initializeFromExistingData() {
      // Reset steps
      this.applicationSteps = [];
      this.usedQuestionNames.clear();

      // If there are existing steps in value prop (form.application)
      if (this.value && this.value.steps && this.value.steps.length > 0) {
        // For each step in the existing application
        this.value.steps.forEach((step) => {
          const newStep = {
            title: step.title,
            questions: [],
          };

          // For each question in the step
          step.questions.forEach((question) => {
            let defaultExtraOptions =
              this.getDefaultExtraOptions(question.type) || [];

            if (question.extraOptions) {
              // Check for missing default options and add them
              defaultExtraOptions.forEach((defaultOption) => {
                // Check if the option with this name already exists in question.extraOptions
                const optionExists = question.extraOptions.some(
                  (existingOption) => existingOption.name === defaultOption.name
                );

                // If the option doesn't exist, add it
                if (!optionExists) {
                  question.extraOptions.push({ ...defaultOption });
                }
              });
            } else {
              // Clone the default options array to avoid reference issues
              question.extraOptions = JSON.parse(
                JSON.stringify(defaultExtraOptions)
              );
            }

            if (question.type === "custom_html") {
              // Add to the step
              newStep.questions.push(question);
              return;
            }

            if (question.type === "schools") {
              // Add to the step
              newStep.questions.push(question);

              // Mark as used
              this.usedQuestionNames.add(question.name);
              return;
            }

            // Find the original question from allJobPostQuestions
            const originalQuestion = this.allJobPostQuestions.find(
              (q) => q.name === question.name
            );

            if (originalQuestion) {
              // Create a question with overridden properties
              const questionCopy = {
                ...originalQuestion,
                // Override with saved values
                label: question.label || originalQuestion.label,
                description:
                  question.description || originalQuestion.description,
                extraOptions: question.extraOptions,
              };

              // Add to the step
              newStep.questions.push(questionCopy);

              // Mark as used
              this.usedQuestionNames.add(question.name);
            }
          });

          // Add the step to application steps
          this.applicationSteps.push(newStep);
        });
      }

      console.info("this.applicationSteps", this.applicationSteps);
    },

    validateApplicationSteps() {
      // Check if all required questions are included
      const requiredQuestions = this.allJobPostQuestions.filter(
        (q) => q.required === true
      );
      const requiredQuestionNames = new Set(
        requiredQuestions.map((q) => q.name)
      );

      // Get all question names from all steps
      const includedQuestionNames = new Set();
      this.applicationSteps.forEach((step) => {
        step.questions.forEach((q) => {
          includedQuestionNames.add(q.name);
        });
      });

      // Find missing required questions
      const missingRequiredQuestions = [];
      requiredQuestionNames.forEach((name) => {
        if (!includedQuestionNames.has(name)) {
          const question = requiredQuestions.find((q) => q.name === name);
          missingRequiredQuestions.push(question);
        }
      });

      return {
        valid: missingRequiredQuestions.length === 0,
        missingQuestions: missingRequiredQuestions,
      };
    },

    // Generate the data object to be emitted to parent
    getApplicationDataForModel() {
      // Format steps
      const steps = this.applicationSteps.map((step) => {
        return {
          title: step.title,
          questions: step.questions.map((q) => ({
            name: q.name,
            label: q.label,
            description: q.description,
            required: q.required,
            private: q.private,
            type: q.type,
            extraOptions: q.extraOptions,
            isCustomType: q.isCustomType,
          })),
        };
      });

      // Create the structure for the parent form component
      return {
        steps: steps,
        schema: {
          steps: steps.length,
          totalQuestions: steps.reduce(
            (count, step) => count + step.questions.length,
            0
          ),
          requiredQuestionsIncluded: this.validateApplicationSteps().valid,
        },
      };
    },

    // Update the parent form.application with current data
    updateApplicationData() {
      const applicationData = this.getApplicationDataForModel();
      this.$emit("input", applicationData);
    },

    addApplicationStep() {
      this.applicationSteps.push({
        title: `Step ${this.applicationSteps.length + 1}`,
        questions: [],
      });
      // updateApplicationData will be called via the watcher
    },

    removeApplicationStep(stepIndex) {
      const step = this.applicationSteps[stepIndex];

      // Make the questions available again
      step.questions.forEach((question) => {
        this.usedQuestionNames.delete(question.name);
      });

      // Remove the step
      this.applicationSteps.splice(stepIndex, 1);
      // updateApplicationData will be called via the watcher
    },

    showQuestionSelector(stepIndex) {
      this.selectedStepIndex = stepIndex;
      this.showModal = true;
    },

    addText(stepIndex) {
      const question = {
        type: "custom_html",
        name: `custom_html_${uuidv4()}`,
        label: "",
        description: "",
        required: false,
        private: false,
        extraOptions: [],
        isCustomType: true,
      };
      this.applicationSteps[stepIndex].questions.push(question);
    },

    hideQuestionSelector() {
      this.showModal = false;
      this.questionSearch = "";
    },

    isQuestionAvailable(question) {
      return !this.usedQuestionNames.has(question.name);
    },

    selectQuestion(question) {
      if (!this.isQuestionAvailable(question)) {
        return; // Question is already used
      }

      const stepIndex = this.selectedStepIndex;
      if (stepIndex === null) return;

      // Create a copy of the question where we can override label and description
      const questionCopy = {
        ...question,
        // Original reference for form validation and submission
        originalName: question.name,
      };

      // Add to the selected step
      this.applicationSteps[stepIndex].questions.push(questionCopy);

      // Mark as used
      this.usedQuestionNames.add(question.name);

      // Close the modal
      this.hideQuestionSelector();

      // Update parent data
      this.updateApplicationData();
    },

    removeQuestionFromStep(stepIndex, questionIndex) {
      const question =
        this.applicationSteps[stepIndex].questions[questionIndex];

      // Make it available again
      this.usedQuestionNames.delete(question.name);

      // Remove it from the step
      this.applicationSteps[stepIndex].questions.splice(questionIndex, 1);

      // Update parent data
      this.updateApplicationData();
    },

    moveQuestion(stepIndex, questionIndex, direction) {
      // Get the questions array for the specified step
      const questions = this.applicationSteps[stepIndex].questions;

      // Check if we can move in the requested direction
      if (direction === "up" && questionIndex === 0) {
        console.log("Cannot move up: already at the top");
        return;
      }

      if (direction === "down" && questionIndex === questions.length - 1) {
        this.$allertWindow("Cannot move down: already at the bottom", "error");
        return;
      }

      const targetIndex =
        direction === "up" ? questionIndex - 1 : questionIndex + 1;

      const updatedQuestions = [...questions];

      const question = updatedQuestions[questionIndex];
      const targetQuestion = updatedQuestions[targetIndex];

      // Swap the questions using Vue.set for reactivity
      this.$set(updatedQuestions, questionIndex, targetQuestion);
      this.$set(updatedQuestions, targetIndex, question);

      // Update the whole questions array to ensure reactivity
      this.$set(
        this.applicationSteps[stepIndex],
        "questions",
        updatedQuestions
      );
    },

    // Check validation and show errors if any
    checkValidation() {
      const validation = this.validateApplicationSteps();
      if (!validation.valid) {
        this.validationErrors = validation.missingQuestions;
      } else {
        this.validationErrors = [];
      }
      return validation.valid;
    },

    validate() {
      return this.checkValidation();
    },
  },
};
</script>

<style scoped>
.application-builder {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.application-step {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 40px;
  padding: 15px;
  background-color: #f9f9f9;
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.step-title-input {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  width: 60%;
}

.add-step-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.remove-btn {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.step-questions {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
}

.assigned-question {
  margin-bottom: 40px;
}

.question-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

.question-type {
  font-size: 12px;
  background-color: #eee;
  padding: 3px 8px;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-content label {
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.question-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #ddd;
}

.property {
  font-size: 12px;
}

.property-label {
  font-weight: bold;
}

.add-question-btn {
  background-color: #2196f3;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 4px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding: 15px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.available-questions {
  max-height: 400px;
  overflow-y: auto;
}

.question-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.question-item:hover {
  background-color: #f5f5f5;
}

.unavailable {
  opacity: 0.5;
  cursor: not-allowed;
}

.validation-errors {
  background-color: #ffebee;
  border: 1px solid #ef9a9a;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.validation-errors h3 {
  color: #c62828;
  margin-top: 0;
}

.steps-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.validate-btn {
  background-color: #ff9800;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.add-question-section {
  display: flex;
  gap: 10px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
