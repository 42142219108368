<template>
  <div>
    <v-tabs v-if="propotions.length" v-model="activeTab">
      <v-tab>Source Image</v-tab>
      <v-tab v-for="tab in propotions" :key="tab.techname">{{
        tab.techname
      }}</v-tab>
      <v-btn
        v-if="localValue.source"
        @click="setVersions(localValue.source)"
        x-small
        style="margin-top: 13px"
        outlined
      >
        Set All Versions to Source
      </v-btn>
    </v-tabs>
    <ImageWithCropper
      @updated="setVersions"
      v-if="activeTab == 0"
      v-model="localValue.source"
      :rules="rules"
      :uploading="uploading"
    />
    <div class="propotions">
      <div v-for="(tab, idx) in propotions" :key="tab.techname">
        <ImageWithCropper
          :source="localValue.source"
          v-if="activeTab == idx + 1"
          v-model="localValue[tab.techname]"
          :settings="{ ratio: tab.w / tab.h, enableCropper: true }"
          :uploading="uploading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithCropper from "./ImageWithCropper.vue";
import { v4 as uuidv4 } from "uuid";

const MAX_IMAGE_SIZE_BYTES = 20 * 1024 * 1024; // 20MB

export default {
  props: {
    settings: Object,
    value: Object,
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    ImageWithCropper,
  },
  data() {
    return {
      activeTab: 0,
      localValue: { ...this.value },
      uploading: false,
      updatingLocalValue: false,
    };
  },
  methods: {
    setVersions($event) {
      this.propotions.map((t) => {
        this.localValue = {
          ...this.localValue,
          [t.techname]: $event,
        };
      });
    },
    validateImage(imageFile) {
      if (imageFile.size > MAX_IMAGE_SIZE_BYTES) {
        const maxSize = (MAX_IMAGE_SIZE_BYTES / (1024 * 1024)).toFixed();
        this.$allertWindow(
          `Image exceeds the max size of ${maxSize}MB (${MAX_IMAGE_SIZE_BYTES} bytes)`,
          "error"
        );
        console.info("image too big");
        return false;
      }

      return true;
    },
    async uploadImage(imageFile) {
      const imageObj = {
        title: `Entity asset (${uuidv4()})`,
        asset: imageFile,
      };

      const image = await this.$api.ImageAsset.create(imageObj);
      return {
        url: image.data.asset?.url,
        xsmall: image.data.asset?.xsmall,
        small: image.data.asset?.small,
        medium: image.data.asset?.medium,
        large: image.data.asset?.large,
        xlarge: image.data.asset?.xlarge,
        version_data: image.data.version_data,
      };
    },
  },
  computed: {
    propotions() {
      return this.settings.proportions || [];
    },
  },
  watch: {
    localValue: {
      deep: true,
      async handler(newLocalValue, oldLocalValue) {
        if (this.uploading) return;
        if (this.updatingLocalValue) return;

        this.updatingLocalValue = true;

        if (newLocalValue instanceof Object) {
          for (const [key, value] of Object.entries(newLocalValue)) {
            if (value instanceof File) {
              const isValid = this.validateImage(value);
              if (!isValid) {
                this.localValue[key] = null;
                break;
              }

              try {
                this.uploading = true;
                const uploadedImage = await this.uploadImage(value);
                console.info("uploadedImage: ", uploadedImage);
                this.localValue[key] = uploadedImage;
              } catch (error) {
                this.localValue[key] = oldLocalValue;
                console.error(error);
                this.$allertWindow(
                  "Error occurred while processing image",
                  "error"
                );
              } finally {
                this.uploading = false;
              }
            }
          }
        }

        this.$emit("input", { ...this.localValue });
        this.updatingLocalValue = false;
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = this.value;
        }
        if (this.localValue.url && !this.localValue.source) {
          this.localValue = {
            ...this.localValue,
            source: {
              url: this.localValue.url,
            },
          };
        }
      },
    },
    uploading(newValue) {
      this.$emit("uploading-changed", newValue);
    },
  },
};
</script>

<style scoped lang="scss">
.propotions::v-deep .uploader {
  background-size: cover !important;
}
</style>
