<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      :items="items"
      :multiple="false"
      outlined
      height="40"
      rounded
      :placeholder="settings.title"
      class="tag-selector"
      clearable
      hide-details="auto"
      v-bind="bind"
      :rules="rules"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, String],
    settings: Object,
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      items: [],
      bind: {},
    };
  },
  created() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      if (this.settings.source == "custom") {
        this.items = this.settings.variants;
      } else {
        const schema_id = this.settings.schema?.schema_id;
        const { data: schema } = await this.$api.DataLoaderSchema.get({
          id: schema_id,
        });
        const { data } = await this.$api.DataLoaderEntity.lite({ schema_id });
        this.items = data.map((t) => ({
          title: t[this.settings.item_text],
          tech_name: t.tech_name,
          schema_tech_name: schema.tech_name,
        }));

        this.bind = {
          itemText: this.settings.item_text,
          returnObject: true,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tag-selector {
  width: 100%;
  max-width: 270px;

  &.multiple {
    max-width: 100%;
  }

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }
  }
}
</style>
