<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Jobs </template>
      <template v-slot:description>
        Add Greenhouse jobs to be tracked for updates and use in applications
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Content Management" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="Add Tracked Job"
      btn-to="/greenhouse/jobs/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search"
      :search-text.sync="searchText"
    />
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel v-for="job in list" :key="job.id" :title="job.name">
          <GreenhouseJobTable
            v-if="job.greenhouse_job_posts.length"
            show-more
            :entity="job"
            :list="job.greenhouse_job_posts"
            @fetch-list="fetchList"
            @sync-job="syncJob"
          />
          <div v-else class="no-entries">No Job Posts</div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Tracked Jobs</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "../../index-page";
import GreenhouseJobTable from "@/components/dataview/greenhouse-jobs.datatable";

export default {
  name: "GreenhouseJobPage",
  components: {
    GreenhouseJobTable,
  },
  extends: IndexPage,
  data() {
    return {
      searchJobs: null,
    };
  },
  computed: {
    filteredNotSticky() {
      return this.computedList.filter((schema) => !schema.is_sticky);
    },
    filteredSticky() {
      return this.computedList.filter((schema) => schema.is_sticky);
    },
  },
  methods: {
    async syncJob(id) {
      console.info("syncJob", id);
      const { data } = await this.$api.GreenhouseJobPost.sync();
      console.info("syncJob", data);
      await this.fetchList();
      this.$allertWindow("Job successfully synced", "success");
    },
    async fetchList() {
      const { data } = await this.$api.GreenhouseJob.get();
      this.list = data;
      console.info("this.list", this.list);
    },
    async destroySchema(schema) {
      const confimed = await this.$confirmWindow({
        title: "Are you sure?",
      });
      if (!confimed) return;

      await this.$api.DataLoaderSchema.destroy({ id: schema.id });
      this.list = this.list.filter((t) => t.id != schema.id);
    },
  },
};
</script>

<style scoped lang="scss">
.stickied-title {
  font-weight: bold;
  padding-bottom: 20px;
}
</style>
