import { render, staticRenderFns } from "./DateTime.vue?vue&type=template&id=563b2468&scoped=true&"
import script from "./DateTime.vue?vue&type=script&lang=js&"
export * from "./DateTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563b2468",
  null
  
)

export default component.exports