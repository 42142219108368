<template>
  <div>
    <Uploader
      :ratio="settings ? settings.ratio : 600 / 400"
      :enableCropper="settings ? settings.enableCropper : false"
      @input="
        $emit('input', $event);
        $emit('updated', $event);
      "
      :value="value"
      :source="source"
      :rules="rules"
      :uploading="uploading"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, File],
    source: [Object, File],
    settings: Object,
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
