<template>
  <TagSelector :items="greenhouse_job_posts" :value="value" @input="updated" />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    condition: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      greenhouse_job_posts: [],
    };
  },
  created() {
    this.setGreenhouseJobPosts();
  },
  methods: {
    async setGreenhouseJobPosts() {
      const { data } = await this.$api.GreenhouseJobPost.get();
      this.greenhouse_job_posts = data.filter((job_post) =>
        this.condition(job_post)
      );
    },
    updated(event) {
      this.$emit("input", event);
      this.$emit(
        "updated",
        this.greenhouse_job_posts.find((post) => (post.id = event))
      );
    },
  },
};
</script>

<style></style>
