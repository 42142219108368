<template>
  <div>
    <div v-if="list">
      <v-data-table
        v-if="list.length"
        :headers="headers"
        :items="list"
        item-key="id"
        show-expand
        class="elevation-0 data-table"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="item in items" :key="item.id">
            <tr class="data-table__row">
              <td />
              <td>
                {{
                  item.greenhouse_updated_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MMM D h:mm A zz"
                    )
                }}
              </td>
              <td>
                {{
                  item.last_synced_at &&
                  item.last_synced_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MMM D h:mm A zz"
                    )
                }}
              </td>
              <td>
                {{ item.title }}
                <span v-if="item.live" class="data-table__row__cancelled">
                  Live
                </span>
                <span v-if="item.active" class="data-table__row__cancelled">
                  Active
                </span>
              </td>
              <td class="text-center nowrap">
                <v-btn
                  color="primary"
                  elevation="0"
                  rounded
                  large
                  @click="$emit('sync-job', item.id)"
                >
                  Sync Job
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-else class="no-entries">No Entries</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Last Updated",
          align: "start",
          value: "greenhouse_updated_at",
        },
        {
          text: "Last Synced",
          align: "start",
          value: "last_synced_at",
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.data-table__row__cancelled {
  font-weight: bold;
  font-style: italic;
  &::before {
    content: "\00a0\00a0";
  }
}
</style>
