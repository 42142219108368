<template>
  <div>
    <div class="page-title">Edit Application</div>
    <Form
      :submit="update"
      v-model="form"
      v-if="form"
      btnText="Update Application"
      edit
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.applications.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      try {
        const { data } = await this.$api.GreenhouseApplication.get({
          id: this.$route.params.id,
        });

        // Ensure application property exists
        if (!data.application) {
          data.application = { steps: [] };
        }

        this.form = data;
        console.log("Form loaded:", this.form);
      } catch (error) {
        console.error("Error loading form:", error);
      }
    },
    async update() {
      try {
        console.log("Submitting form:", JSON.stringify(this.form));
        await this.$api.GreenhouseApplication.update(this.form);
        this.$allertWindow("Successfully updated");
      } catch (error) {
        console.error("Error updating application:", error);
        this.$allertWindow("Error updating application", "error");
      }
    },
  },
};
</script>
