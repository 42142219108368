<template>
  <div>
    <div class="uploader-file-wrapper" v-if="value && value.attachment_id">
      <a target="_blank" :href="value.url">
        <v-icon color="primary">$download</v-icon>
      </a>
    </div>
    <div
      class="uploader-wrapper"
      :style="{ maxWidth: ratio * 300 + 'px' }"
      v-else
    >
      <div class="uploader__actions" v-if="src">
        <v-btn
          style="margin-right: 10px"
          @click="recrop"
          outlined
          fab
          small
          color="primary"
          v-if="enableCropper"
        >
          <v-icon>$crop</v-icon>
        </v-btn>
        <v-btn @click="remove" fab small color="primary">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>
      <label
        :style="{
          backgroundImage: `url(${src})`,
          backgroundPosition: position,
          borderRadius: circle ? '50%' : '20px',
          backgroundSize: size,
        }"
        class="uploader"
        @drop.prevent="drop"
        @dragover.prevent
      >
        <v-responsive class="uploader__placeholder" :aspect-ratio="ratio">
          <div v-if="value && value.vimeo_id && !value.preview.url">
            Processing video...
          </div>
          <div v-if="uploading">
            <v-card style="display: inline-block" class="px-5 py-2">
              <div
                class="d-flex flex-column align-center justify-center"
                style="gap: 8px"
              >
                <span>Uploading...</span>
                <v-progress-circular
                  indeterminate
                  color="#EE3724"
                ></v-progress-circular>
              </div>
            </v-card>
          </div>
          <v-btn
            v-if="!uploading"
            tag="div"
            color="default"
            fab
            class="elevation-0 uploader__upload-btn"
          >
            <v-icon>$plus</v-icon>
          </v-btn>
        </v-responsive>
        <input
          ref="fileUploader"
          accept="image/*,video/*,application/pdf"
          @change="handleChange"
          class="uploader__input"
          type="file"
        />
      </label>
      <v-text-field
        ref="validation"
        class="uploader__validation"
        :value="value"
        type="hidden"
        hide-details="auto"
        :rules="rules"
      />
      <ImageCropper
        :open="dialog"
        :srcFile="uploadedFile"
        @cancel="onCancel"
        @confirm="onConfirm"
        :width="500"
        :ratio="ratio"
        :stencil-size="{
          width: 280,
          height: 280,
        }"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Uploader",
  props: {
    value: [File, Object],
    source: [File, Object],
    placeholder: String,
    ratio: {
      type: Number,
      default: 580 / 320,
    },
    circle: Boolean,
    position: {
      type: String,
      default: "center",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "contain",
    },
    enableCropper: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    uploadedFile: null,
  }),
  methods: {
    handleChange(event) {
      const file = event.target.files[0];
      if (file) {
        file.uuid = uuidv4();
        if (this.enableCropper) {
          this.openCropper(file);
        } else {
          this.$emit("input", file);
        }
      }
    },
    drop(event) {
      const file = event.dataTransfer.files[0];
      file.uuid = uuidv4();
      // if (this.enableCropper) {
      //   this.openCropper(file);
      // } else {
      this.$emit("input", file);
      // }
    },
    recrop() {
      this.openCropper(this.source || this.value);
    },
    remove() {
      this.$emit("input", null);
      this.$refs.fileUploader.value = null;
    },
    openCropper(file) {
      this.uploadedFile = file;
      this.dialog = true;
    },
    onCancel() {
      this.dialog = false;
      this.$refs.fileUploader.value = null;
    },
    onConfirm(file) {
      this.dialog = false;
      this.$emit("input", file);
      this.$refs.fileUploader.value = null;
    },
  },
  computed: {
    src() {
      if (this.value instanceof File) {
        return this.uploading ? "" : URL.createObjectURL(this.value);
      }
      if (this.value instanceof Object) {
        return this.value.preview?.thumb?.url || this.value.url;
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.uploader {
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid var(--v-border-base);
  background-size: cover;
  background-position: center;
  display: block;
  position: relative;

  &-file-wrapper {
    margin-top: 15px;
    a {
      text-decoration: none;
    }
  }

  &-wrapper {
    border-radius: 20px;
    position: relative;
    background: white;
    background-image: linear-gradient(45deg, #80808099 25%, transparent 25%),
      linear-gradient(-45deg, #80808099 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #80808099 75%),
      linear-gradient(-45deg, transparent 75%, #80808099 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }

  &__actions {
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }

  &__validation {
    &:not(.v-input--has-state) {
      display: none !important;
    }
    padding: 0 !important;
    &::v-deep {
      .v-input__slot {
        display: none !important;
      }
    }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__upload-btn {
    background: #f5f5f5e0 !important;
  }

  &__input {
    display: none;
  }
}
</style>
