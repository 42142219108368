<template>
  <div :class="{ 'link-mode': linkMode }">
    <div
      :style="[!display(field) ? { display: 'none' } : []]"
      v-for="(field, idx) in schema"
      :key="idx"
      class="schema-field"
    >
      <component
        :is="fullWidth(field) ? 'div' : 'FormRow'"
        :label="field.title"
        :tooltip="field.helper_text"
        :required="field.required"
      >
        <component
          :label="field.title"
          :settings="field"
          v-model="data[field.tech_name]"
          :is="`SchemaBuilder${field.type}`"
          :rules="field.required ? [validation.required] : []"
          @uploading-changed="
            (status) => handleUploadingChanged(field.tech_name, status)
          "
        ></component>
        <template #tooltip-next v-if="data.__links__[field.tech_name]">
          <v-tooltip open-delay="500" max-width="300" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                @click="copyLink(field.tech_name)"
                style="position: relative; top: -2px; left: 10px"
              >
                <v-icon size="16">$copy_link</v-icon>
              </v-btn>
            </template>

            <span>Copy link to field</span>
          </v-tooltip>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import { copyToClipboard, getRanHex } from "@/lib";
export default {
  props: {
    value: Object,
    schemaId: String,
  },
  data() {
    return {
      schema: null,
      linkMode: false,
      data: { ...this.value, __links__: { ...(this.value.__links__ || {}) } },
      uploadingFields: {},
    };
  },
  created() {
    this.linkMode = "link" in this.$route.query;
    this.setSchema();
  },
  methods: {
    fullWidth(field) {
      if (["Html", "RichText", "HasMany", "HasOne"].includes(field.type))
        return true;
      return false;
    },
    display(field) {
      if (!this.linkMode) return true;
      if (["HasMany", "HasOne"].includes(field.type)) {
        // console.log(JSON.stringify(this.data[field.tech_name]));
        if (JSON.stringify(this.data[field.tech_name]).includes(this.uuid)) {
          return true;
        }
      }

      if (!this.uuid) return true;
      const uuid = this.data.__links__[field.tech_name].split("_").at(-1);
      if (this.uuid == uuid) return true;
      return false;
    },

    async setSchema() {
      const { data } = await this.$api.DataLoaderSchema.get({
        id: this.schemaId,
      });
      this.schema = data.schema;
      this.schema_tech_name = data.tech_name;
      this.$emit("setName", data.title);

      this.schema.map((t) => {
        if (!this.data.__links__[t.tech_name]) {
          this.data.__links__[t.tech_name] = this.generateEasyUpdateLink(
            getRanHex(8)
          );
        }
      });
      this.data = { ...this.data };
    },
    copyLink(tech_name) {
      const { schema_id, id } = this.$route.params;
      if (schema_id && id) copyToClipboard(`${this.data.__links__[tech_name]}`);
    },
    generateEasyUpdateLink(uuid) {
      const { schema_id, id } = this.$route.params;
      if (schema_id && id) {
        return `${schema_id}_${id}_${uuid}`;
      }
      return false;
    },
    handleUploadingChanged(fieldId, isUploading) {
      this.$set(this.uploadingFields, fieldId, isUploading);
    },
  },
  computed: {
    uuid() {
      return this.$route.query.link.split("_").at(-1);
    },
    isAnyFieldUploading() {
      // Return true if any field is currently uploading
      return Object.values(this.uploadingFields).some(
        (status) => status === true
      );
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.data) != JSON.stringify(this.value))
          this.data = { ...this.value };
      },
    },
    data: {
      deep: true,
      handler() {
        this.$emit("input", {
          ...this.data,
          schema_tech_name: this.schema_tech_name,
        });
      },
    },
    isAnyFieldUploading(newValue) {
      this.$emit("uploading-status-changed", newValue);
    },
  },
};
</script>

<style scoped lang="scss"></style>
