<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Details">
        <FormRow
          required
          label="Title"
          tooltip="This is the title for your Schema."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Title"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow
          required
          label="Tech Name"
          tooltip="This is the Tech Name for your Schema."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Tech Name"
            v-model="form.tech_name"
          ></TextField>
        </FormRow>
        <FormRow
          required
          label="Internal"
          tooltip="Enable this option to hide the form from non-Admins"
          v-if="['super_admin'].includes(currentUser.active_team_role)"
        >
          <Checkbox placeholder="Internal" v-model="form.internal"></Checkbox>
        </FormRow>
        <FormRow required label="Schema Type">
          <Dropdown
            placeholder="Schema Type"
            v-model="form.schema_type"
            :items="schemaTypes"
            :disabled="formScope !== 'new'"
          ></Dropdown>
        </FormRow>
        <FormRow
          label="Stickied"
          tooltip="Enable this option to pin schema at the top of your list"
        >
          <Checkbox placeholder="Stickied" v-model="form.is_sticky"></Checkbox>
        </FormRow>
        <FormRow
          label="Default Virtual Component"
          v-if="form.schema_type === 'page'"
          tooltip="If set, this custom component will be added to all entities/pages created under this schema"
        >
          <CustomComponentSelector
            v-model="form.default_virtual_component_id"
          />
        </FormRow>

        <FormRow label="Dynamic Page" v-if="form.schema_type === 'regular'">
          <template #tooltip
            >Optional. If entities in this schema will be shown on a dynamic
            page (with a url like “/work/:work_name”, where “:work_name” is
            replaced by the entity’s tech name), select that page
            here.</template
          >
          <PagesSelector
            multiple
            v-model="form.page_ids"
            :condition="(page) => page.url && page.url.indexOf(':') != -1"
          ></PagesSelector>
        </FormRow>
      </FormPanel>

      <FormPanel label="Schema">
        <div
          class="schema-field"
          v-for="(field, idx) in form.schema"
          :key="idx"
        >
          <div class="schema-field-menu">
            <v-select
              label="Type"
              :items="fieldTypes"
              v-model="field.type"
              :rules="[validation.required]"
              hide-details
            ></v-select>
            <v-text-field
              label="Field Name"
              v-model="field.title"
              :rules="[validation.required]"
              hide-details
            ></v-text-field>
            <v-text-field
              label="Tech Name"
              v-model="field.tech_name"
              hide-details
              :rules="[validation.required]"
            ></v-text-field>
            <v-text-field
              label="Helper Text"
              v-model="field.helper_text"
              hide-details
            ></v-text-field>
          </div>
          <div class="schema-field-component">
            <v-checkbox
              v-model="field.required"
              label="Required"
              v-if="isRequirable(field.type)"
            ></v-checkbox>
            <component
              :value="field"
              :is="`SchemaField${field.type}`"
              :schema-uuid="form.id"
              @update="update(idx, $event)"
            ></component>
          </div>
          <div class="schema-field__btns">
            <v-btn icon @click="up(idx)">
              <v-icon>$arrow_up</v-icon>
            </v-btn>
            <v-btn icon @click="down(idx)">
              <v-icon>$arrow_down</v-icon>
            </v-btn>
            <v-btn @click="deleteField(idx)" icon>
              <v-icon> $delete </v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn @click="addField" color="primary" block large outlined
          >+ Add Field / Relation</v-btn
        >
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      fieldTypes: [
        "TextField",
        "TextArea",
        "RichText",
        "Html",
        "Number",
        "DateTime",
        "Boolean",
        "Image",
        "Select",
        "MultiSelect",
        "HasMany",
        "HasOne",
      ],
      schemaTypes: [
        { text: "Default", value: "regular" },
        { text: "Singleton", value: "singleton" },
        { text: "Page", value: "page" },
        { text: "Dynamic Page", value: "dynamic_page" },
      ],
    };
  },
  methods: {
    up(idx) {
      if (idx < 1) return;
      const { schema } = this.form;
      const element = { ...schema[idx] };
      schema.splice(idx, 1);
      schema.splice(idx - 1, 0, element);
    },
    down(idx) {
      const { schema } = this.form;
      if (idx >= schema.length - 1) return;
      const element = { ...schema[idx] };
      schema.splice(idx, 1);
      schema.splice(idx + 1, 0, element);
    },
    addField() {
      this.form.schema.push({
        title: "",
        type: "TextField",
        tech_name: "",
      });
    },
    async deleteField(idx) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      this.form.schema.splice(idx, 1);
    },
    update(idx, $event) {
      this.form.schema.splice(idx, 1, $event);
    },
    isRequirable(fieldType) {
      return !["HasMany", "HasOne", "Html", "MultiSelect", "RichText"].includes(
        fieldType
      );
    },
  },
  computed: {
    formScope() {
      // Split the pathname into segments using "/" as the delimiter
      const segments = window.location.pathname.split("/");
      // Get the last segment (the last part of the URL)
      return segments[segments.length - 1];
    },
  },
};
</script>

<style scoped lang="scss">
.schema-field {
  padding: 15px;
  border: 2px solid;
  margin-bottom: 10px;
  position: relative;

  & .form-row,
  &::v-deep .form-row {
    max-width: calc(100% - 100px);
  }

  &__btns {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-menu {
    display: flex;
    align-items: center;
    max-width: calc(100% - 100px);

    & > * {
      margin: 0 10px;
    }
  }

  &-component {
    padding: 10px;
    max-width: 550px;
  }
}
</style>
