<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Applications </template>
      <template v-slot:description>
        Add Greenhouse jobs to be tracked for updates and use in applications
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Content Management" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Application"
      btn-to="/greenhouse/applications/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search"
      :search-text.sync="searchText"
    />

    <div v-if="list">
      <div v-if="computedList.length">
        <GreenhouseApplicationsDatatable
          :list="computedList"
          @destroy="destroy"
        />
      </div>
      <div v-else class="no-entries">No Articles</div>
    </div>
    <Spinner v-else relative />

    <!-- <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          class="sticky-panels"
          :action-path="`/greenhouse/applications/${app.id}`"
          :title="list.id"
        >
          <GreenhouseApplicationsDatatable
            :list="computedList"
            @fetch-list="fetchList"
          />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Entities</div>
    </ListPanels> -->
  </div>
</template>

<script>
import GreenhouseApplicationsDatatable from "../../../components/dataview/greenhouse-applications.datatable.vue";
import IndexPage from "../../index-page";

export default {
  name: "GreenhouseApplicationPage",
  components: {
    GreenhouseApplicationsDatatable,
  },
  extends: IndexPage,
  computed: {},
  methods: {
    async duplicateSchema({ id }) {
      await this.$api.DataLoaderSchema.duplicate({ id });
      this.fetchList();
    },
    async fetchList() {
      const { data } = await this.$api.GreenhouseApplication.get();
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.GreenhouseApplication.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss">
.stickied-title {
  font-weight: bold;
  padding-bottom: 20px;
}
</style>
