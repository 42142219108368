<template>
  <div>
    <TextArea
      :placeholder="label"
      @input="$emit('input', $event)"
      :value="value"
      hide-details
      :rules="rules"
    ></TextArea>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
