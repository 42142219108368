import Vue from "vue";
import { axios } from "./api.config";
import { crud, objectToFormData } from "@/lib";

Vue.prototype.$http = axios;

const Auth = {
  login: (params) => axios.post("/auth/login", params),
  register: (params) => axios.post("/auth/register", params),
  resetPassword: (params) => axios.post("/auth/reset_password", params),
  setPassword: (params) => axios.post("/auth/set_password", params),
  checkPasswordToken: (params) =>
    axios.post("/auth/check_password_token", params),
};

const Datasources = {
  get: (params) => axios.get("/datasources", { params, jwt: true }),
};

const Logs = {
  get: (params) => axios.get("/logs", { params, jwt: true }),
};

const DirectChat = {
  get: (params = {}) =>
    axios.get(`/direct_chats${params.id ? "/" + params.id : ""}`, {
      params,
      jwt: true,
    }),
  messages: (params = {}) =>
    axios.get(`/direct_chats/messages`, {
      params,
      jwt: true,
    }),
  deleteMessage: (params = {}) =>
    axios.delete(`/direct_chats/delete_message`, {
      params,
      jwt: true,
    }),
  readMessage: (params = {}) =>
    axios.get(`/direct_chats/read_message`, {
      params,
      jwt: true,
    }),
  sendMessage: (params) =>
    axios.post("/direct_chats/send_message", params, { jwt: true }),
  updateMessage: (params) =>
    axios.put("/direct_chats/update_message", params, { jwt: true }),
  create: (params) => axios.post("/direct_chats", params, { jwt: true }),
};

const Dashboard = {
  events: (params) => axios.get("/dashboard/events", { params, jwt: true }),
  pages: (params) => axios.get("/dashboard/pages", { params, jwt: true }),
  counters: (params) => axios.get("/dashboard/counters", { params, jwt: true }),
};

const BlogArticle = {
  ...crud("blog_articles"),
};

const DataLoaderSchema = {
  ...crud("data_loader/schemas"),
  list: (params) =>
    axios.get("data_loader/schemas/list", {
      params,
      jwt: true,
    }),
  lite: () => axios.get("data_loader/schemas/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`data_loader/schemas/${params["id"]}/duplicate`, params, {
      jwt: true,
    }),
};

const DataLoaderEntity = {
  ...crud("data_loader/schemas/<%=schema_id%>/entities"),
  lite: (params) =>
    axios.get(`data_loader/schemas/${params["schema_id"]}/entities/lite`, {
      params,
      jwt: true,
    }),
  rearrange: (params) =>
    axios.post(
      `data_loader/schemas/${params["schema_id"]}/entities/rearrange`,
      params,
      { jwt: true }
    ),
  duplicate: (params) =>
    axios.post(
      `data_loader/schemas/${params["schema_id"]}/entities/${params["id"]}/duplicate`,
      params,
      {
        jwt: true,
      }
    ),
};

const BlogTag = {
  ...crud("blog_tags"),
};

const Release = {
  ...crud("releases"),
};

const CustomComponent = {
  ...crud("custom_components"),
  lite: () => axios.get("custom_components/lite", { jwt: true }),
};

const ImageAsset = {
  ...crud("image_assets"),
};

const Search = {
  search: (params) => axios.get("search", params, { jwt: true }),
};

const Deployment = {
  ...crud("deployments"),
  cancel: (params) => axios.post("/deployments/cancel", params, { jwt: true }),
};

const Theme = {
  ...crud("themes"),
  getActive: () => axios.get("/themes/active", { jwt: true }),
  lite: (params) => axios.get("themes/lite", { params, jwt: true }),
};

const Profile = {
  get: () => axios.get("/profile/current", { jwt: true }),
  setEnv: (params) => axios.put("/profile/set_env", params, { jwt: true }),
  update: (params, additional) =>
    axios.put("/profile/update", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  onboard: (params, additional) =>
    axios.put("/profile/onboard", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
  unlock: (params) => axios.post("/profile/unlock", params, { jwt: true }),
};

const Project = {
  ...crud("projects"),
  makeActive: (params) =>
    axios.put(`/projects/${params.id}/make_active`, params, { jwt: true }),
  // duplicate: (params) =>
  //   axios.post(`/projects/duplicate`, params, { jwt: true }),
  archive: (params) =>
    axios.put(`/projects/${params.id}/archive`, params, { jwt: true }),
  unarchive: (params) =>
    axios.put(`/projects/${params.id}/unarchive`, params, { jwt: true }),
};

const Addon = {
  ...crud("addons"),
};

const Event = {
  ...crud("events"),
  lite: () => axios.get("events/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/events/duplicate`, params, { jwt: true }),
};

const VenueEvent = {
  ...crud("venue_events"),
  lite: () => axios.get("venue_events/lite", { jwt: true }),
};

const SubmissionForm = {
  ...crud("submission_forms"),
  lite: () => axios.get("submission_forms/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/submission_forms/duplicate`, params, { jwt: true }),
  export: (params) =>
    axios.post(`/submission_forms/download.csv`, params, {
      jwt: true,
      responseType: "blob",
    }),
};

const RegistrationForm = {
  ...crud("registration_forms"),
  lite: () => axios.get("registration_forms/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/registration_forms/duplicate`, params, { jwt: true }),
};

const Submission = {
  ...crud("submissions"),
  lite: (params) => axios.get("submissions/lite", { params, jwt: true }),
  bulkPublish: (params) =>
    axios.post(`submissions/bulk_publish`, params, { jwt: true }),
  bulkUnpublish: (params) =>
    axios.post(`submissions/bulk_unpublish`, params, {
      jwt: true,
    }),
  moveUp: (params) => axios.post(`submissions/move_up`, params, { jwt: true }),
  moveDown: (params) =>
    axios.post(`submissions/move_down`, params, { jwt: true }),
};

const Presentation = {
  ...crud("presentations"),
  duplicate: (params) =>
    axios.post(`/presentations/duplicate`, params, { jwt: true }),
};

const Page = {
  ...crud("pages"),
  lite: () => axios.get("pages/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/pages/duplicate`, params, { jwt: true }),
  updateComponents: (params) =>
    axios.put(`pages/${params.id}/components`, params, { jwt: true }),
  upMenu: (params) => axios.post(`pages/up_menu`, params, { jwt: true }),
  downMenu: (params) => axios.post(`pages/down_menu`, params, { jwt: true }),
};

const Team = {
  ...crud("teams"),
  exit: (params) =>
    axios.put(`/teams/${params.id}/exit`, params, { jwt: true }),
  makeActive: (params) =>
    axios.put(`/teams/${params.id}/make_active`, params, { jwt: true }),
};

const TeamMember = {
  get: (params) =>
    axios.get(`team_members/${params.id}`, { params, jwt: true }),
  sendEmail: (params) => {
    axios.post("/team_members/send_email", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  update: (params, additional) =>
    axios.put("/team_members/update_member", objectToFormData(params), {
      jwt: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const loaded = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (additional?.onUploadProgress) {
          return additional.onUploadProgress(loaded);
        }
      },
    }),
};

const Invite = {
  register: (params) => axios.post(`/invites/register`, params, { jwt: true }),
  accept: (params) => axios.put(`/invites/accept`, params, { jwt: true }),
  reject: (params) => axios.put(`/invites/reject`, params, { jwt: true }),
  get: (params) => axios.get("invites/get", { params, jwt: true }),
};

const User = {
  ...crud("users"),
  lite: (params = {}) => axios.get("users/lite", { params, jwt: true }),
  confirm: (params) =>
    axios.put(`/users/${params.id}/confirm`, params, { jwt: true }),
};

const Schedule = {
  ...crud("schedules"),
  lite: () => axios.get("schedules/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/schedules/duplicate`, params, { jwt: true }),
};

const ScheduleItem = {
  ...crud("schedule_items"),
  lite: (params) => axios.get("schedule_items/lite", { params, jwt: true }),
};

const Admin = {
  ...crud("admins"),
};

const Role = {
  ...crud("roles"),
  lite: () => axios.get("roles/lite", { jwt: true }),
};

const Countdown = {
  ...crud("countdowns"),
  duplicate: (params) =>
    axios.post(`/countdowns/duplicate`, params, { jwt: true }),
};

const ContentSection = {
  ...crud("content_sections"),
  lite: () => axios.get("content_sections/lite", { jwt: true }),
  duplicate: (params) =>
    axios.post(`/content_sections/duplicate`, params, { jwt: true }),
  updateBlocks: (params) =>
    axios.put(`/content_sections/${params.id}/blocks`, params, { jwt: true }),
};

const ContentBlock = {
  ...crud("content_blocks"),
  duplicate: (params) =>
    axios.post(`/content_blocks/duplicate`, params, { jwt: true }),
};

const SupportForm = {
  ...crud("support_forms"),
  duplicate: (params) =>
    axios.post(`/support_forms/duplicate`, params, { jwt: true }),
};

const SupportTicket = {
  ...crud("support_tickets"),
};

const SubmissionContent = {
  ...crud("submission_contents"),
};

const List = {
  ...crud("lists"),
  lite: () => axios.get("lists/lite", { jwt: true }),
  duplicate: (params) => axios.post(`/lists/duplicate`, params, { jwt: true }),
  updateEntries: (params) =>
    axios.put(`/lists/${params.id}/entries`, params, { jwt: true }),
};
const ListEntry = {
  ...crud("list_entries"),
  lite: (params) => axios.get("list_entries/lite", { params, jwt: true }),
};

const Notification = {
  ...crud("notifications"),
  sendNotification: (params) =>
    axios.post(`/notifications/send_notification`, params, { jwt: true }),
  duplicate: (params) =>
    axios.post(`/notifications/duplicate`, params, { jwt: true }),
};

const HtmlBlock = {
  ...crud("html_blocks"),
  duplicate: (params) =>
    axios.post(`/html_blocks/duplicate`, params, { jwt: true }),
};

const TextBlock = {
  ...crud("text_blocks"),
  duplicate: (params) =>
    axios.post(`/text_blocks/duplicate`, params, { jwt: true }),
};

const Chat = {
  ...crud("chats"),
  deleteMessage: ({ message_id }) =>
    axios.delete(`/chats/delete_message/${message_id}`, { jwt: true }),
};

const Variable = {
  ...crud("variables"),
  lite: () => axios.get("variables/lite", { jwt: true }),
  find: (params) => axios.get(`/variables/find`, { params, jwt: true }),
};

const TicketmasterVenue = {
  ...crud("ticketmaster_venues"),
  lite: (params) =>
    axios.get("ticketmaster_venues/lite", { params, jwt: true }),
  search: (params) =>
    axios.get("ticketmaster_venues/search", { params, jwt: true }),
};

const VenueEventsList = {
  ...crud("venue_events_lists"),
  duplicate: (params) =>
    axios.post(`/venue_events_lists/duplicate`, params, { jwt: true }),
};

const GreenhouseJob = {
  ...crud("greenhouse/jobs"),
  lite: (params) => axios.get("greenhouse/jobs/lite", { params, jwt: true }),
  search: () => axios.get("greenhouse/jobs/search", { jwt: true }),
};

const GreenhouseJobPost = {
  get: (params) => axios.get("greenhouse/job_posts", { params, jwt: true }),
  search: () => axios.get("greenhouse/jobs/search", { jwt: true }),
  sync: () => axios.get("greenhouse/sync", { jwt: true }),
};

const GreenhouseApplication = {
  ...crud("greenhouse/applications"),
  lite: (params) =>
    axios.get("greenhouse/applications/lite", { params, jwt: true }),
  // search: (params) =>
  //   axios.get("ticketmaster_venues/search", { params, jwt: true }),
};

Vue.prototype.$api = {
  Theme,
  Admin,
  Auth,
  User,
  Datasources,
  Project,
  Profile,
  Team,
  TeamMember,
  Event,
  VenueEvent,
  Role,
  Chat,
  SupportForm,
  Dashboard,
  ContentSection,
  ContentBlock,
  Countdown,
  List,
  ListEntry,
  Notification,
  TextBlock,
  Schedule,
  ScheduleItem,
  Invite,
  Page,
  SubmissionForm,
  Submission,
  Addon,
  HtmlBlock,
  SupportTicket,
  SubmissionContent,
  Release,
  Deployment,
  Presentation,
  CustomComponent,
  Search,
  ImageAsset,
  RegistrationForm,
  Logs,
  DirectChat,
  Variable,
  TicketmasterVenue,
  VenueEventsList,
  BlogArticle,
  BlogTag,
  DataLoaderSchema,
  DataLoaderEntity,
  GreenhouseJob,
  GreenhouseJobPost,
  GreenhouseApplication,
};
