import { render, staticRenderFns } from "./AddonGreenhouse.vue?vue&type=template&id=59a82da6&scoped=true&"
import script from "./AddonGreenhouse.vue?vue&type=script&lang=js&"
export * from "./AddonGreenhouse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a82da6",
  null
  
)

export default component.exports