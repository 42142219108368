var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.value && _vm.value.attachment_id)?_c('div',{staticClass:"uploader-file-wrapper"},[_c('a',{attrs:{"target":"_blank","href":_vm.value.url}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$download")])],1)]):_c('div',{staticClass:"uploader-wrapper",style:({ maxWidth: _vm.ratio * 300 + 'px' })},[(_vm.src)?_c('div',{staticClass:"uploader__actions"},[(_vm.enableCropper)?_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"outlined":"","fab":"","small":"","color":"primary"},on:{"click":_vm.recrop}},[_c('v-icon',[_vm._v("$crop")])],1):_vm._e(),_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("$delete")])],1)],1):_vm._e(),_c('label',{staticClass:"uploader",style:({
        backgroundImage: ("url(" + _vm.src + ")"),
        backgroundPosition: _vm.position,
        borderRadius: _vm.circle ? '50%' : '20px',
        backgroundSize: _vm.size,
      }),on:{"drop":function($event){$event.preventDefault();return _vm.drop($event)},"dragover":function($event){$event.preventDefault();}}},[_c('v-responsive',{staticClass:"uploader__placeholder",attrs:{"aspect-ratio":_vm.ratio}},[(_vm.value && _vm.value.vimeo_id && !_vm.value.preview.url)?_c('div',[_vm._v(" Processing video... ")]):_vm._e(),(_vm.uploading)?_c('div',[_c('v-card',{staticClass:"px-5 py-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"gap":"8px"}},[_c('span',[_vm._v("Uploading...")]),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#EE3724"}})],1)])],1):_vm._e(),(!_vm.uploading)?_c('v-btn',{staticClass:"elevation-0 uploader__upload-btn",attrs:{"tag":"div","color":"default","fab":""}},[_c('v-icon',[_vm._v("$plus")])],1):_vm._e()],1),_c('input',{ref:"fileUploader",staticClass:"uploader__input",attrs:{"accept":"image/*,video/*,application/pdf","type":"file"},on:{"change":_vm.handleChange}})],1),_c('v-text-field',{ref:"validation",staticClass:"uploader__validation",attrs:{"value":_vm.value,"type":"hidden","hide-details":"auto","rules":_vm.rules}}),_c('ImageCropper',{attrs:{"open":_vm.dialog,"srcFile":_vm.uploadedFile,"width":500,"ratio":_vm.ratio,"stencil-size":{
        width: 280,
        height: 280,
      }},on:{"cancel":_vm.onCancel,"confirm":_vm.onConfirm}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }