<template>
  <v-expansion-panel class="form-panel" :readonly="readonly">
    <v-expansion-panel-header>
      <div class="form-panel-header">
        <slot name="label">{{ label }}</slot>
        <slot name="actions"></slot>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    label: String,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.form-panel-header {
  display: flex;
  justify-content: space-between;
}
.form-panel {
  &.v-expansion-panel:before {
    box-shadow: none !important;
  }

  &.v-expansion-panel:after {
    display: none !important;
  }

  &::v-deep {
    .v-expansion-panel-header {
      color: var(--v-text-base);
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      border-bottom: 2px solid var(--v-border-base);
      padding: 26px 40px;
      .v-icon__svg {
        height: 34px;
        width: 34px;
      }
    }
    .v-expansion-panel-content {
      border-bottom: 2px solid var(--v-border-base);
    }
    .v-expansion-panel-content__wrap {
      padding: 30px 40px;
    }
  }

  &.v-expansion-panel:not(.v-expansion-panel--active):last-child
    .v-expansion-panel-header {
    margin-bottom: 5px;
  }
}
</style>
