<template>
  <v-form ref="form">
    <FormPanels readonly>
      <FormPanel label="HTML Block Info">
        <template #label> <div>Job Information</div> </template>
        <!-- <template #actions>
        </template> -->

        <div v-if="greenhouseJobsFetching">
          <v-progress-circular
            :value="0"
            :size="35"
            :width="3"
            :rotate="-90"
            style="margin-right: 5px"
          >
          </v-progress-circular>
          Fetching Jobs...
        </div>
        <FormRow v-else label="Greenhouse Job" required>
          <!-- <TextField placeholder="" v-model="form.greenhouse_id"></TextField> -->
          <v-autocomplete
            v-model="form.greenhouse_id"
            :items="greenhouseJobs"
            item-text="name"
            item-value="id"
            :rules="[validation.required]"
            label="Greenhouse Job"
          ></v-autocomplete>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter
      :refs="$refs"
      :submit="submit"
      :disabled="greenhouseJobsFetching || greenhouseJobsFetchError"
    >
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {
      greenhouseJobsFetching: false,
      greenhouseJobsFetchError: false,
      greenhouseJobs: null,
    };
  },
  async created() {
    try {
      this.greenhouseJobsFetching = true;
      await this.fetchJobs();
    } catch (error) {
      this.greenhouseJobsFetchError = true;
      this.$allertwindow(`An error occurred: ${error.message}`, "alert");
    } finally {
      this.greenhouseJobsFetching = false;
    }
  },
  methods: {
    async fetchJobs() {
      this.greenhouseJobsFetching = true;
      const { data } = await this.$api.GreenhouseJob.search();
      this.greenhouseJobs = data;
    },
  },
};
</script>

<style lang="scss">
.v-form .v-expansion-panels .v-expansion-panel-header__icon {
  display: none !important;
}
.form-panel-header {
  align-items: center;
}
</style>
